import { Link } from "gatsby"
import React from "react"
import "./header.css"

const Header = ({ siteTitle }) => (
  <header className="header">
    <h1 className="header-title">
      <Link to="/" className="header-anchor">
        {siteTitle}
      </Link>
    </h1>
  </header>
)

export default Header
