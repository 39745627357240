const friends = [
  {
    name: "Mama",
    nickname: "Mama/Dolor/Tyang",
    img: "031.jpg",
    fullname: "Dolores Reyes Gesilva Calleja",
  },
  {
    name: "Jun",
    nickname: "Junior/Long Hair/Jun/Tacio/Tyong",
    img: "004.jpg",
    fullname: "Anastacio Tibay Calleja Jr.",
  },
  {
    name: "Balot",
    nickname: "Kuya Balot",
    img: "007.jpg",
    fullname: "Vincent Gerald Gesilva Calleja",
  },
  {
    name: "Shake",
    nickname: "Ate Shake Shake",
    img: "024.jpg",
    fullname: "Crisanta Anaia Cabading Calleja",
  },
  {
    name: "Keefe",
    nickname: "Keefe/Mr. Kiwi",
    img: "027.jpg",
    fullname: "Vincent Keefe Cabading Calleja",
  },
  {
    name: "Meng",
    nickname: "Meng Meng",
    img: "030.jpg",
    fullname: "Jasmine Gesilva Calleja",
  },
  {
    name: "Dolf",
    nickname: "Kuya Dolf",
    img: "029.jpg",
    fullname: "Randolf Prado",
  },
  {
    name: "Jaiden",
    nickname: "Kuya Jaiden",
    img: "025.jpg",
    fullname: "Jaiden Raine Calleja Prado",
  },
  {
    name: "Tacia",
    nickname: "Tashing/Baby Tacia",
    img: "028.jpg",
    fullname: "Anastacia Reign Calleja Prado",
  },
  {
    name: "Angge",
    nickname: "Angge/Angie/Anj/Tita Yaya",
    img: "006.jpg",
    fullname: "Angeline Gesilva Calleja",
  },
  {
    name: "Precy",
    nickname: "Tita Precy",
    img: "017.jpg",
    fullname: "Felicitacion Gesilva Gruta",
  },
  {
    name: "Ciothy",
    nickname: "Ditche/Ciothy",
    img: "014.jpg",
    fullname: "Cionhel Gesilva Gruta",
  },
  {
    name: "Nino",
    nickname: "Kuya Niño",
    img: "015.jpg",
    fullname: "Nifled Niño Gesilva Gruta",
  },
  {
    name: "Cora",
    nickname: "Nanay Cora",
    img: "016.jpg",
    fullname: "Corazon Gruta",
  },
  {
    name: "Pogi",
    nickname: "Tito Pogi",
    img: "018.jpg",
    fullname: "Abundio Reyes Gesilva Jr.",
  },
  {
    name: "Minyang",
    nickname: "Tita Minyang",
    img: "002.jpg",
    fullname: "Herminia Callos",
  },
  {
    name: "Bert",
    nickname: "Tito Bert",
    img: "001.jpg",
  },
  {
    name: "Borong",
    nickname: "Borong",
    img: "026.jpg",
  },
  {
    name: "Normie",
    nickname: "Normie",
    img: "003.jpg",
    fullname: "Normita Callos",
  },
  {
    name: "Al",
    nickname: "Kuya Al",
    img: "008.jpg",
  },
  {
    name: "Liam",
    nickname: "Liam",
    img: "005.jpg",
  },
  {
    name: "Tita",
    nickname: "Tita Jovy",
    img: "013.jpg",
    fullname: "Jovita Peñara",
  },
  // {
  //   name: "?",
  //   img: "009.jpg",
  // },
  // {
  //   name: "?",
  //   img: "010.jpg",
  // },
  // {
  //   name: "?",
  //   img: "011.jpg",
  // },
  // {
  //   name: "?",
  //   img: "012.jpg",
  // },
  {
    name: "Shervs",
    nickname: "Shervs",
    img: "019.jpg",
  },
  {
    name: "Leanne",
    nickname: "Leanne",
    img: "020.jpg",
  },
  {
    name: "Gina",
    nickname: "Gina",
    img: "021.jpg",
    fullname: "Ginalyn Baniqued",
  },
  {
    name: "Mutya",
    nickname: "Mutya",
    img: "022.jpg",
    fullname: "Sheila Encinares",
  },
  // {
  //   name: "?",
  //   img: "023.jpg",
  // },
]

export default friends
